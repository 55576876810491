import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'SearchAsset',
  props: ['currentLoc', 'searchParams'],
  components: {
    appStrings,
    DatePicker
  },
  watch: {
  },
  data() {
    return {
      unsubscribe: null,
      showAddTransferAssetModal: false,
      transferDate: null,
      loader: false,
      fromGstn: null,
      toGstn: null,
      batchNo: null,
      description: null,
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: {
        text: null,
        value: null
      },
      bookType: {
        text: null,
        value: null
      },
      fromLocation: {
        text: null,
        value: null
      },
      toLocation: {
        text: null,
        value: null
      },
      period: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      transferStatus: {
        text: null,
        value: null
      },
      batchType: null,
      transferType: 'INTERNAL',
      address1: null,
      address2: null,
      city: null,
      state: null,
      pin: null,
      lessorState: {
        value: null,
        text: null
      },
      lessorCountry: {
        value: '100',
        text: 'India'
      }
    };
  },
  validations: {
    toLocation: {
      text: {
        required
      }
    },
    fromLocation: {
      text: {
        required
      }
    },
    bookType: {
      text: {
        required
      }
    },
    legalEntity: {
      text: {
        required
      }
    }
  },
  mounted() {
    if(this.$route.name === 'LocDash') {
      this.batchType = 'IT';
    } else {
      this.batchType = 'NON-IT';
    }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.createTransferBatch();
        }
      }
    });

    if (this.searchParams) {
      this.getParentToChildData(this.searchParams);
    }
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.parent_value_set_id = this.lessorCountry.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.locType = 'from_loc';
          this.getGstnFromLocation(this.fromLocation.value);
          this.bookType = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookType = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.LOCATION:
          if (this.locType === 'from_loc') {
            this.fromLocation = {
              text: item.value_code,
              value: item.value_set_dtl_id
            };
            this.getGstnFromLocation(this.fromLocation.value);
          } else if (this.locType === 'to_loc') {
            this.toLocation = {
              text: item.value_code,
              value: item.value_set_dtl_id
            };
            this.getGstnFromLocation(this.toLocation.value);
          }
          break;
        case appStrings.VALUESETTYPE.ASSET_SPOTON_LOC_VSET:
          if (this.locType === 'from_loc') {
            this.fromLocation = {
              text: item.value_code,
              value: item.value_set_dtl_id
            };
            this.getGstnFromLocation(this.fromLocation.value);
          } else if (this.locType === 'to_loc') {
            this.toLocation = {
              text: item.value_code,
              value: item.value_set_dtl_id
            };
            this.getGstnFromLocation(this.toLocation.value);
          }
          break;
        case appStrings.VALUESETTYPE.ASSET_TRANSFER_BATCH_STATUS:
          this.batchStatus = {
            value: item.value_meaning,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.TRANSFER_STATUS:
          this.transferStatus = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
        case appStrings.VALUESETTYPE.SELECT_COUNTRY:
          this.lessorCountry.text = item.value_code;
          this.lessorCountry.value = item.value_set_dtl_id;
          this.lessorState.text = null;
          this.lessorState.value = null;
          this.lessorCity = null;
          this.lessorPincode = null;
          break;
        case appStrings.VALUESETTYPE.SELECT_STATE:
          this.lessorState.text = item.value_code;
          this.lessorState.value = item.value_set_dtl_id;
          this.lessorCity = null;
          this.lessorPincode = null;
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    chooseValueset(name, locType, leValue) {
      this.locType = locType === 'from' ? 'from_loc' : 'to_loc';
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'book_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_BOOK_TYPE);
          break;
        case 'hr_location_from':
        case 'hr_location_to':
          if (leValue === '13708') {
            this.openValueSetModal(
              appStrings.VALUESETTYPE.ASSET_SPOTON_LOC_VSET
            );
            break;
          } else {
            this.openValueSetModal(appStrings.VALUESETTYPE.LOCATION);
            break;
          }
        case 'asset_transfer_batch_status':
          this.openValueSetModal(
            appStrings.VALUESETTYPE.ASSET_TRANSFER_BATCH_STATUS
          );
          break;
        case 'TRANSFER_STATUS':
          this.openValueSetModal(appStrings.VALUESETTYPE.TRANSFER_STATUS);
          break;
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
        case appStrings.VALUESETTYPE.ASSET_SPOTON_LOC_VSET:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    getGstnFromLocation(locationId) {
      const payload = {
        locId: locationId,
        leId: this.legalEntity.value
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLocationBasedGstn', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (this.locType === 'from_loc') {
              this.fromGstn = result;
            } else if (this.locType === 'to_loc') {
              this.toGstn = result;
            }
            this.locType = null;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    createTransferBatch() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const sameLocations = this.checkSameLocations();
        if (sameLocations) {
          this.toLocation = {
            value: null,
            text: null
          };
          this.toGstn = null;
          return alert('From Location & To Location cannot be same!');
        }
        const payload = {
          book_type_code: this.bookType.value,
          le_id: this.legalEntity.value,
          location_from_id: this.fromLocation.value,
          location_to_id: this.toLocation.value,
          transfer_type: this.transferType,
          address1: this.address1,
          address2: this.address2,
          city: this.city,
          state: this.lessorState.text,
          country: this.lessorCountry.text,
          pincode: this.pin,
          batch_type: this.batchType
        };
        if(this.transferType === 'EXTERNAL'){
          if(!payload.address1 || !payload.city || !payload.pincode || !payload.state){
            this.$bvToast.toast('Please fill mandatory fields', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }else{
            this.loader = true;
            this.$store
              .dispatch('assets/createTransferBatch', payload)
              .then(response => {
                this.loader = false;
                if (response.status === 201) {
                  this.showAlert = true;
                  this.isSuccess = true;
                  this.responseMsg = response.data.message;
                  const result = response.data.data;
                  this.batchNo = result.batch_num;
                  this.period = result.period;
                  this.$emit(
                    'searchCreatedBatch',
                    this.batchNo,
                    this.legalEntity,
                    this.bookType,
                    true
                  );
                } else {
                  this.showAlert = true;
                  this.isSuccess = false;
                  this.responseMsg = response.response.data.message;
                }
              })
              .catch(() => {
                this.loader = false;
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              });
          }
        }
        else if(this.transferType !== 'EXTERNAL'){
          this.loader = true;
          this.$store
            .dispatch('assets/createTransferBatch', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 201) {
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                const result = response.data.data;
                this.batchNo = result.batch_num;
                this.period = result.period;
                this.$emit(
                  'searchCreatedBatch',
                  this.batchNo,
                  this.legalEntity,
                  this.bookType,
                  true
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }

      }
    },
    checkSameLocations() {
      if (this.fromLocation.value === this.toLocation.value) {
        return true;
      }
      return false;
    },
    getParentToChildData(params) {
      this.legalEntity = params.legalEntity;
      this.bookType = params.bookType;
      this.fromLocation = params.fromLocation;
      this.locType = 'from_loc';
      this.getGstnFromLocation(this.fromLocation.value);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
